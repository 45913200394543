import { Pipe, PipeTransform } from '@angular/core';
import { TimeFormatType } from '../model/school.model';
import { convertTime12to24 } from '../util/date-util';

@Pipe({
    name: 'timeFormat',
    standalone: true,
    pure: true, // required to update the value when the promise is resolved
})
export class TimeFormatPipe implements PipeTransform {
    format: TimeFormatType;
    lastValue: string;
    _value: string;
    constructor() {}

    transform(value: string, timeFormat: TimeFormatType): any {
        this.format = timeFormat;
        if (value == this.lastValue) {
            return this._value;
        }
        this.lastValue = value;
        return (this._value = convertTime12to24(value, this.format));
    }
}
